<template>
  <div>
    <b-breadcrumb class="mb-1">
      <b-breadcrumb-item href="/">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <b-breadcrumb-item v-on:click="goBack">Devis</b-breadcrumb-item>
      <b-breadcrumb-item active>Nouveau Devis</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card>
      <b-card no-body class="invoice-preview-card">
        <b-card-body class="invoice-padding pb-0">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <!-- Header: Left Content -->
            <div>
              <div class="logo-wrapper">
                <b-img
                  :src="enterprise.logo"
                  width="120"
                  alt="placeholder"
                ></b-img>
                <h3 class="text-primary invoice-logo">
                  {{ enterprise.name }}
                </h3>
              </div>

              <b-card-text class="mb-25">
                {{ enterprise.address }} - {{ enterprise.country.name }}
              </b-card-text>
            </div>

            <!-- Header: Right Content -->
            <div class="invoice-number-date mt-md-0 mt-2">
              <div
                class="d-flex align-items-center justify-content-md-end mb-1"
              >
                <h4 class="invoice-title">Devis N° : </h4>
                <b-form-group
                  class="input-group-merge invoice-edit-input-group disabled"
                >
                  <b-form-input
                    readonly
                    v-model="invoice.devis_num"
                    id="invoice-data-id"
                  />
                  <small
                    v-show="showErrors"
                    class="text-danger"
                    v-if="invoice.devis_num == '00000'"
                    >Veuillez renseigner le numéro du devis</small
                  >
                </b-form-group>
              </div>
              <div
                class="d-flex align-items-center justify-content-md-end mb-1"
              >
                <span class="title"> Date: </span>
                <flat-pickr
                  v-model="today"
                  class="form-control col-5 invoice-edit-input"
                  disabled
                />
              </div>
            </div>
          </div>
        </b-card-body>
        <hr class="invoice-spacing" />

        <b-card-body class="invoice-padding pt-0">
          <b-row class="invoice-spacing">
            <b-col cols="12" xl="6" class="mb-lg-1">
              <h6 class="mb-2">
                Client : <br />
                <small
                  v-show="showErrors"
                  class="text-danger"
                  v-if="!customer.name"
                >
                  <feather-icon icon="AlertTriangleIcon" /> Veuillez
                  sélectionner un client</small
                >
              </h6>
              <v-select
                placeholder="Veuillez choisir un client"
                v-model="customerMultiSelect"
                :options="customers"
                label="name"
                input-id="invoice-data-client"
                :clearable="false"
              >
                <template #list-header>
                  <li
                    v-b-toggle.sidebar-invoice-add-new-customer
                    class="add-new-client-header d-flex align-items-center my-50"
                  >
                    <feather-icon icon="PlusIcon" size="16" />
                    <span class="align-middle ml-25">Ajouter un client</span>
                  </li>
                </template>
              </v-select>

              <div class="mt-1" v-if="customer.name">
                <validation-observer ref="editCustomerForm">
                  <b-form>
                    <b-card-text class="mb-25"
                      ><span class="d-flex justify-content-between"
                        ><b>Nom : </b>
                        <b-input
                          :readonly="editCustomer"
                          class="col-md-8 ky-input"
                          v-model="customer.name"
                          size="sm"
                      /></span>
                    </b-card-text>
                    <b-card-text class="mb-25">
                      <span class="d-flex justify-content-between"
                        ><b>Adresse : </b>
                        <b-input
                          :readonly="editCustomer"
                          class="col-md-8 ky-input"
                          v-model="customer.address"
                          size="sm"
                      /></span>
                    </b-card-text>
                    <b-card-text class="mb-25"
                      ><span class="d-flex justify-content-between"
                        ><b>Téléphone : </b>
                        <b-input
                          :readonly="editCustomer"
                          class="col-md-8 ky-input"
                          v-model="customer.telephone"
                          size="sm"
                      /></span>
                    </b-card-text>
                    <b-card-text class="mb-0"
                      ><span class="d-flex justify-content-between"
                        ><b>E-mail : </b>
                        <b-input
                          :readonly="editCustomer"
                          class="col-md-8 ky-input"
                          v-model="customer.email"
                          size="sm"
                      /></span>
                      <br />

                      <hr />
                      <span class="d-flex justify-content-between"
                        ><b-link
                          v-if="editCustomer == false"
                          @click="editCustomer = !editCustomer"
                          >Annuler</b-link
                        >
                        <b-link
                          v-if="editCustomer == false"
                          @click="editThisCustomer()"
                          >Sauvegarder</b-link
                        >
                        <b-link
                          v-if="editCustomer == true"
                          @click="editCustomer = !editCustomer"
                          >Modifier ce client</b-link
                        ></span
                      >
                    </b-card-text>
                  </b-form>
                </validation-observer>
              </div>
            </b-col>

            <!-- Col: Payment Details -->
          </b-row>

          <div class="col-md-3" style="float: right">
            <validation-provider
              #default="{ errors }"
              :rules="'required'"
              name="TVA"
            >
              <b-form-group label="TVA(%)">
                <b-form-input type="number" v-model="invoice.tva" />
                <small class="text-danger"> {{ errors[0] }} </small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              :rules="'required'"
              name="remise"
            >
              <b-form-group label="Remise(%)">
                <b-form-input type="number" v-model="invoice.discount" />
                <small class="text-danger"> {{ errors[0] }} </small>
              </b-form-group>
            </validation-provider>
          </div>
        </b-card-body>

        <div class="ml-1 mr-0 justify-content-center"></div>

        <div class="card">
          <div class="card-body">
            <span v-if="isEmpty == true" class="text-danger"
              >Veuillez associer des prestations à cette facture</span
            >
            <all-customer-tasks
              class="mb-4"
              :customer="customer"
              :totalTVA="totalTVA"
              :totalDiscount="totalDiscount"
              :invoiceTotal="invoiceTotal"
              :invoiceTTC="invoiceTTC"
              :advancePaymentsTotal="advancePaymentsTotal"
              :finalAmount="finalAmount"
              ref="billableTasks"
            />
            <div class="row">
              <div class="col-md-4" v-if="state == 0">
                <b-btn @click="generateInvoice" :disabled="generateIsProgress">
                  <b-spinner v-if="generateIsProgress" small />
                  <b-icon icon="file-earmark-break" v-else /> Générer le devis
                </b-btn>
              </div>
              <template v-if="state == 1">
                <div class="col-auto">
                  <b-btn variant="success">
                    <b-icon icon="download"></b-icon> Télecharger
                  </b-btn>
                </div>
                <div class="col-auto">
                  <b-btn variant="info">
                    <b-icon icon="mailbox"></b-icon> Envoyer au client
                  </b-btn>
                </div>
              </template>
            </div>
          </div>
        </div>
      </b-card>
    </b-card>
    <b-sidebar
      title="Nouveau client"
      id="sidebar-invoice-add-new-customer"
      ref="mySideBar"
      bg-variant="white"
      width="30%"
      right
      shadow
    >
      <b-card>
        <validation-observer ref="addCustomerForm">
          <b-media-body class="text-center">
            <!-- +++++++++++ -->
            <b-avatar
              class="mb-3"
              size="7em"
              :src="imageUrl || client.logo"
              :icon="'image'"
            >
              <template #badge>
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  id="logo"
                  @change="onFileUpload"
                />
                <b-icon
                  @click="openFilePicker"
                  style="cursor: pointer"
                  icon="camera"
                ></b-icon>
              </template>
            </b-avatar>
            <!-- +++++++++++ -->
          </b-media-body>
          <b-form>
            <ky-input
              :rules="'required'"
              v-model="client.name"
              :type="'text'"
              :placeholder="'Nom du client'"
            />

            <ky-input
              :rules="isEmailRequired"
              v-model="client.email"
              :type="'email'"
              :placeholder="'E-mail du client'"
            />
            <ky-input
              :rules="isTelephoneRequired"
              v-model="client.telephone"
              :type="'number'"
              :placeholder="'N° de téléphone du client'"
            />
            <ky-input
              :rules="''"
              v-model="client.address"
              :type="'text'"
              :placeholder="'Adresse du client'"
            />
            <ky-input
              v-model="client.matricule"
              :type="'number'"
              :placeholder="'SIRET du client'"
            />
            <ky-input
              :rules="''"
              v-model="client.website"
              :type="'text'"
              :placeholder="'Site du client'"
            />
            <div class="text-center">
              <b-row>
                <b-col>
                  <b-button block @click="addThisCustomer()"
                    >Enregistrer</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-sidebar>
  </div>
</template>

<script>
import KyInput from "@/@core/layouts/components/ky-input.vue";
import kyInlineInput from "@/@core/layouts/components/ky-inline-input.vue";
import Multiselect from "vue-multiselect";
import KyAutocomplete from "@/@core/layouts/components/ky-autocomplete.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import AllCustomerTasks from "../../invoice/AllCustomerTasks.vue";
import { BToast } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { now } from "@vueuse/shared";
export default {
  components: {
    kyInlineInput,
    Multiselect,
    KyAutocomplete,
    AllCustomerTasks,
    BToast,
    ToastificationContent,
    KyInput,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      editCustomer: true,
      isEmpty: false,
      canGenerate: false,
      enterprise: {},
      today: new Date().toISOString().split("T")[0],
      customerMultiSelect: null,
      generateIsProgress: false,
      readOnly: false,
      uploaded: false,
      imageUrl: null,
      showErrors: false,
      errors: "",
      theSum: 0,
      invoice: {
        devis_num: "",
        tva: 20,
        discount: 0,
        tasks: [
          {
            id: 0,
            price: 0,
          },
        ],
        customer: {
          id: "",
          name: "",
          address: "",
          telephone: 0,
          email: "",
        },
      },

      total: 0,
      searchCustomer: "",
      state: 0,
      client: {
        id: "",
        name: "",
        telephone: "",
        logo: "",
        email: "",
        website: "",
        matricule: "",
        address: "",
      },
      clientDefault: {
        id: "",
        name: "",
        telephone: "",
        logo: "",
        email: "",
        website: "",
        matricule: "",
        address: "",
      },
      customer: {
        name: "",
      },

      popover: false,
      errors: {},
      errorMessage: "",
      error: "",
      showSidebar: false,
    };
  },

  computed: {
    ...mapState("tasks", [
      "billableTasks",
      "tasksWithoutCustomer",
      "lastdevis",
    ]),
    ...mapGetters("tasks", ["varGetLastDevis"]),
    ...mapState("enterprise", { customers: (state) => state.clients }),

    filteredCustomers() {
      return (
        this.customers.filter((item) =>
          item.name.toLowerCase().includes(this.customer.name.toLowerCase())
        ) || this.customers
      );
    },

    isEmailRequired() {
      if (this.client.telephone == "") {
        return "required";
      }
    },
    isTelephoneRequired() {
      if (this.client.email == "") {
        return "required";
      }
    },
  },

  methods: {
    ...mapActions("enterprise", [
      "fetchCustomers",
      "addCustomer",
      "getInfo",
      "updateCustomer",
    ]),
    ...mapActions("tasks", ["allTasksDevis", "getLastDevis"]),

    async editThisCustomer() {
      const valid = await this.$refs.editCustomerForm.validate();
      if (!valid) return;
      this.updateCustomer(this.$formData(this.customer))
        .then((response) => {
          this.editCustomer = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Client modifié avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Données existantes",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    onFileUpload(event) {
      this.uploaded = true;
      var file = event.target.files[0];
      if (file) this.imageUrl = URL.createObjectURL(file);

      this.client.logo = file;
      this.client.logo_only = 1;
    },
    openFilePicker() {
      document.getElementById("logo").click();
    },

    async addThisCustomer() {
      const valid = await this.$refs.addCustomerForm.validate();
      if (!valid) return;
      this.showLoading = true;
      this.addCustomer(this.$formData(this.client))
        .then((r) => {
          this.$refs.mySideBar.hide();
          this.client.name = "";
          this.client.telephone = "";
          this.client.logo = "";
          this.client.email = "";
          this.client.website = "";
          this.client.matricule = "";
          this.client.address = "";
          this.$refs.addCustomerForm.reset();
          this.fetchCustomers();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Client ajouter avec succès",
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.showLoading = false;
          this.errorMessage = error;
          this.errors = error.response;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    goBack() {
      history.back();
    },
    invoiceTotal() {
      var sum = 0;
      for (let item = 0; item < this.billableTasks.length; item++) {
        if (this.billableTasks[item].price == null) {
          return "0";
        } else {
          sum += Number(this.billableTasks[item].price);
        }
      }
      this.theSum = sum - (sum * this.invoice.discount) / 100;
      return (
        sum -
        (sum * this.invoice.discount) /
          (100)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      );
    },

    advancePaymentsTotal() {
      return 0;
    },

    finalAmount() {
      return 0;
    },

    totalDiscount() {
      // calculate the total discount
      var sum = 0;
      for (let item = 0; item < this.billableTasks.length; item++) {
        if (this.billableTasks[item].price == null) {
          return "0";
        } else {
          sum += Number(this.billableTasks[item].price);
        }
      }
      var discountAmount = (sum * this.invoice.discount) / 100;
      return discountAmount
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    totalTVA() {
      // calculate the total tva
      var tva = this.invoice.tva;
      var total = this.invoiceTotal();
      var tvaAmount = (total * tva) / 100;
      return tvaAmount
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    invoiceTTC() {
      var myTTC = this.theSum + (this.theSum * this.invoice.tva) / 100;
      return myTTC
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    generateInvoice(data) {
      if (
        this.customer.email == null ||
        this.customer.email == "" ||
        this.customer.email == "null"
      ) {
        this.showErrors = true;
      } else {
        this.generateIsProgress = true;

        this.invoice.tasks = this.billableTasks.map((item) => {
          return { id: item.id, price: item.price };
        });
        this.invoice.customer = this.customer;
        this.invoice.num = "0";

        var formData = new FormData();
        formData.append("invoice", JSON.stringify(this.invoice));
        this.allTasksDevis(formData)
          .then((e) => {
            this.generateIsProgress = false;
            this.$router.push({ name: "admin-devis" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Devis généré avec succès",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.generateIsProgress = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Données invalides",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    select(item) {},

    selectCustomer(item) {
      this.customer = JSON.parse(JSON.stringify(item));
    },
  },

  watch: {
    customerMultiSelect(val) {
      this.customer = this.customerMultiSelect;
      if (this.customer.name != "") {
        this.invoice.customer_id = this.customer.id;
      }
    },

    lastdevis(val) {
      if (val) this.invoice.devis_num = val;
    },
  },

  mounted() {
    this.getInfo().then((r) => {
      this.enterprise = r.data.data;
    });
    this.invoice.devis_num = this.lastdevis;
    this.fetchCustomers();
    this.getLastDevis();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
%scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: #f8f4f4;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: silver;
  }
}
// input[type="text"] {
//   border: none;
//   border-bottom: 1px dotted grey;
//   min-width: 280px;
//   outline: none;
//   color: #6e6b7b;
//   font-weight: 500;
// }

// .form-group > label {
//   top: 18px;
//   left: 6px;
//   position: relative;
//   background-color: white;
//   padding: 0px 5px 0px 5px;
//   font-size: 0.9em;
// }

.multiselect {
  width: 50%;
}
::v-deep.multiselect__tags {
  border-radius: 0px;
  margin: 0px;
  border: none !important ;
  border-bottom: 1px dotted grey;
  max-width: 100%;
  outline: none;
}

.custom-auto {
  top: 25px;
  max-height: 150px;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  position: static;
  @extend %scrollbar;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style scoped>
.ky-input {
  border: none;
}
</style>